#Report
{
	text-align: center;
}

#Report .deal
{
	margin: 10px 0;
}

#Report .deal-header:first-child.sideways
{
	/* writing-mode: sideways-lr; */
	writing-mode: vertical-lr;
	border-radius: 0;
}

#Report .deal-header:first-child.sideways *
{
	transform:rotate(180deg);
}

#Report .deal-body .table
{
	margin: 0;
}

#Report .deal-body *:not(:first-child)
{
	margin-top: .3rem;
}

#Report .deal-body *:not(:last-child)
{
	margin-bottom: .3rem;
}
