#Form
{
	text-align: center;
	display:          flex;
	height:           100vh;
	width:            100vw;
	align-items:      center;
	justify-content:  center;
}

#Form .DateRangePicker,
#Form .DateRangePickerInput
{
	width: 100%;
}

#Form .DateRangePickerInput
{
	display:        flex;
	flex-direction: row;
}

#Form .DateInput
{
	width: auto;
}

#Form .DateRangePickerInput_arrow
{
	display:         flex;
	flex-direction:  column;
	justify-content: center;
}

#iexec_report_start_date,
#iexec_report_end_date
{
	text-align: center;
}
